import 'react-circular-progressbar/dist/styles.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { FacebookShareButton } from "react-share";
import { FacebookIcon } from "react-share";
import { IMAGE_TYPE } from '../../constant/constant';
import AutorigButton from '../ButtonComponent/ButtonComponent';
import { useTranslation } from 'react-i18next';
import { convertImgSrc } from '../../utils/util';
import LazyBackground from '../ImageTransformation/LazyBackground';

const ImageResult = () => {
	const location = useLocation();
	const { state } = location || {};
	const { url_anime, url_origin, url_concated, url_share_page } = state || {};

	const { t: translate } = useTranslation();
	const ImageComponent = () => {
		const navigate = useNavigate();
		return (
			<LazyBackground>
				<div className="img-result">
					<main>
						<div className="container display-flex-col">
							<AutorigButton type={IMAGE_TYPE.NAVIGATE} btnClassName="btn-primary" onClick={() => { navigate("/"); }}>{translate('newImg')}</AutorigButton>
							<br />
							<div className="img-result-display unconcated">
								<img
									alt="result avatar"
									src={convertImgSrc(url_origin)}
								/>
								<img
									alt="result avatar"
									src={convertImgSrc(url_anime)}
								/>
								<br />
							</div>
							<div className="img-result-display concated">
								<img
									alt="result avatar"
									src={convertImgSrc(url_concated)}
								/>
								<br />
							</div>
                            <a className="btn btn-primary" href={convertImgSrc(url_concated)} target="_blank" download rel="noopener noreferrer"><span>{translate("downloadAnimePhoto")}</span></a>
                            <br />
                            <FacebookShareButton
								url={convertImgSrc(url_share_page)}
								hashtag={"#beahero"}
								className="container display-flex-row display-flex"
							>
								<AutorigButton type={IMAGE_TYPE.NAVIGATE} btnClassName="share-btn" onClick={() => { }}>
									<FacebookIcon size={30} round />
									<span className='share-fb'>{translate('fbShare')}</span>
								</AutorigButton>
							</FacebookShareButton>
						</div>
					</main>
				</div>

			</LazyBackground>
		);
	}

	return (
		<ImageComponent />
	);
};

export default ImageResult;