import { useState } from "react";
import { useTranslation } from "react-i18next";

const HeroSelectionComponent = ({selectAction, items:banners, activeItem}) => {
  const { t: translate } = useTranslation();
  const [index,setIndex] = useState(0);
  const handleActiveItemLeft = () => {
    const index = banners.findIndex(item => activeItem.id === item.id) || banners.length;
    const item = banners[index - 1];
    setIndex(randomImgIndex());
    selectAction(item);
    document.documentElement.style.setProperty('--primary-theme-color', item.primaryColor);
  }
  
  const handleActiveItemRight = () => {
    const index = banners.findIndex(item => activeItem.id === item.id);
    const isLastItem = index === banners.length - 1;
    const item = banners[isLastItem ? 0 : index + 1];
    setIndex(randomImgIndex());
    selectAction(item);
    document.documentElement.style.setProperty('--primary-theme-color', item.primaryColor);
  }
  const randomImgIndex = () => {
    return Math.floor(Math.random() * activeItem.img.length);
  }

  return (
    <div className="carousel-list">
        <div className="container flex-start">
          <div className="choose-hero-container container flex-start">
          <h2 className="choose-hero-title">{translate('chooseHero')}</h2>
          <div className="container display-flex-col choose-hero-icon-container">
          <i className="fa-solid fa-chevron-down choose-hero-icon"></i>
          <i className="fa-solid fa-chevron-down"></i>
          </div>
          </div>
        </div>
        <div className={`carousel-item ${activeItem.id === '' ? 'active' : ''}`}>
        <i className="fa-solid fa-chevron-left icon-left carousel-icon" onClick={() => handleActiveItemLeft()}></i>
        <div className="carousel-content" style={{ backgroundImage: activeItem.backGroungImg}}>
          <div className="carousel-content-text">
          {/* <h3 className="carousel-content-title">{activeItem.title}</h3> */}
          {!!activeItem.title && <img className="hero-logo" src={activeItem.title} alt="logo" />}
          <p className="carousel-content-content">{activeItem.text}</p>
          </div>
          <img src={activeItem.img[index]} className="hero-img" alt="hero" />
        </div>
        <i className="fa-solid fa-chevron-right icon-right carousel-icon" onClick={() => handleActiveItemRight()}></i>
      </div>
    </div>
  )

}
export default HeroSelectionComponent;