import { ScreenProps } from "./types";
import facebook from "../assets/images/facebook.svg";
import tiktok from "../assets/images/tik-tok.svg";
import tryimg1 from "../assets/images/tryimg1.jpg";
import tryimg2 from "../assets/images/tryimg2.jpg";
import tryimg3 from "../assets/images/tryimg3.jpg";
import tryimg4 from "../assets/images/tryimg4.jpg";
import demo1 from "../assets/images/demo1.jpg";
import demo2 from "../assets/images/demo2.jpg";
import demo3 from "../assets/images/demo3.jpg";
import demo4 from "../assets/images/demo4.jpg";
import demo5 from "../assets/images/demo5.jpg";
import demo6 from "../assets/images/demo6.jpg";
import demo7 from "../assets/images/demo7.jpg";
import demo8 from "../assets/images/demo8.jpg";
import demo9 from "../assets/images/demo9.jpg";
import demo10 from "../assets/images/demo10.jpg";
import demo11 from "../assets/images/demo11.jpg";
import demo12 from "../assets/images/demo12.jpg";
import qc1 from "../assets/images/qc1.jpg";
import qc2 from "../assets/images/qc2.jpg";
import qc3 from "../assets/images/qc3.jpg";
import qc4 from "../assets/images/qc4.jpg";
import qc5 from "../assets/images/qc5.jpg";
import qc6 from "../assets/images/qc6.jpg";
import qc7 from "../assets/images/qc7.jpg";
import qc8 from "../assets/images/qc8.jpg";
import qc9 from "../assets/images/qc9.jpg";
import qc10 from "../assets/images/qc10.jpg";
import haderLogo from "../assets/images/beahero.png";
import backGroundImg from "../assets/images/back-ground-img.jpg";
import ironman from "../assets/images/character/iron_man_character_1.png";
import blackpanther from "../assets/images/character/black_panther_character_1.png";
import drstrange from "../assets/images/character/dr_strange_character_1.png";
import loki from "../assets/images/character/loki_character_1.png";
import spiderman from "../assets/images/character/spider_man_character_1.png";
import starlord from "../assets/images/character/star_lord_character_1.png";
import thanos from "../assets/images/character/thanos_character_1.png";
import thor from "../assets/images/character/thor_character_1.png";

import ironman_background from "../assets/images/character-background/iron_man_backgound.jpg";
import blackpanther_background from "../assets/images/character-background/black_panther_background.jpg";
import drstrange_background from "../assets/images/character-background/dr_strange_background.webp";
import loki_background from "../assets/images/character-background/loki_background.jpg";
import spiderman_background from "../assets/images/character-background/spider_man_background.png";
import starlord_background from "../assets/images/character-background/star_lord_background.jpg";
import thanos_background from "../assets/images/character-background/thanos_background.jpg";
import thor_background from "../assets/images/character-background/thor_background.jpg";
//WEB CONFIG CONSTs
export const ANIME_PAGE_TITLE: ScreenProps = {
    mainTitle: "Manga",
    subTitle: "Transformation Photo"
}

export const imageWidth = 512;
export const imageHeight = 512;

//api call constant
export const api_url = 'https://api_gateway.img2anime.click';
export const api_path_token = "/token";
export const api_path_request_job = "/genimg/v1/img2img";
export const api_path_request_job_progress = "/genimg/v1/jobprogress";

//host name constant
export const WEB_URL = 'https://beahero.click/';
//web url parameter
export const url_parameter = 'beahero';

export const token_params = {
    "username": "apigwu",
    "pass_hash": "GUjcR1MGf589697276effb09"
}

export const googleAdSlot = {
    leftAd: '7398259278',
    rightAd: '6040165329',
    bottomAd: '3345291598',
    topAd: '7092964918',
    queueModel: '8381048707'
}
export const websiteHashTag = "#beahero";
export const QUICK_OVERVIEW_CONTENTS = [
    {title:"quickOverviewTitle1",content: "quickOverviewContent1", img: "https://nginx.img2anime.click/static_manga/demo/demo3.webp", img_alt:"demo art 21"},
    {title:"quickOverviewTitle2",content: "quickOverviewContent2", img: "https://nginx.img2anime.click/static_manga/demo/demo6.webp", img_alt:"demo art 20"},
    {title:"quickOverviewTitle3",content: "quickOverviewContent3", img: "https://nginx.img2anime.click/static_manga/demo/demo9.webp", img_alt:"demo art 19"}
]
//export const BACKGROUND_IMG = "https://nginx.img2anime.click/static/background_img2anime.webp";
export const BACKGROUND_IMG = backGroundImg;
export const BACKGROUND_IMG_MOBILE = "";
export const GALLERY_IMGS = [
    demo1,demo2,demo3,qc1,qc2,qc3,demo4,demo5,demo6,qc4,qc5,qc6,demo7,demo8,demo9,qc7,qc8,qc9,demo10,demo11,demo12,qc10
];
export const BANNER_IMGS = [
    {img_src:"https://nginx.img2anime.click/static_manga/banner/banner1.jpg", img_alt: "banner demo 1"},
    {img_src:"https://nginx.img2anime.click/static_manga/banner/banner2.jpg", img_alt: "banner demo 2"},
    {img_src:"https://nginx.img2anime.click/static_manga/banner/banner3.jpg", img_alt: "banner demo 3"},
    {img_src:"https://nginx.img2anime.click/static_manga/banner/banner4.jpg", img_alt: "banner demo 4"}
]
export const TRIAL_IMGS = [
    {img_src:"http://nginx.img2anime.click/static_manga/try_img/tryimg1.jpeg", img_alt: "trial image 1"},
    {img_src:"http://nginx.img2anime.click/static_manga/try_img/tryimg2.jpeg", img_alt: "trial image 2"},
    {img_src:"http://nginx.img2anime.click/static_manga/try_img/tryimg3.jpeg", img_alt: "trial image 3"},
    {img_src:"http://nginx.img2anime.click/static_manga/try_img/tryimg4.jpeg", img_alt: "trial image 4"}
]

export const TRIAL_IMGS_LOCAL = [
    {img_src:tryimg4, img_alt: "trial image 1"},
    {img_src:tryimg2, img_alt: "trial image 2"},
    {img_src:tryimg3, img_alt: "trial image 3"},
    {img_src:tryimg1, img_alt: "trial image 4"}
]
export const HEADER_LOGO = haderLogo;
export const PAYLOAD_IMG_MODEL = "be_a_hero";
export const SOCIAL_MEDIA = [{
    id: "Facebook",
    icon: facebook,
    qrCode: "",
    mobileUrl: "fb://page/taoanhAI199x",
    webURl: "https://www.facebook.com/taoanhAI199x/",
    style: "social-media-btn-fb"
},
{
    id: "Tik tok",
    icon: tiktok,
    qrCode: "",
    mobileUrl: "",
    webURl: "https://www.tiktok.com/@kaymangashop?_t=8k2fhOXGRW5&_r=1",
    style: "social-media-btn-tiktok"
},]
export const HERO_BANNERS = [
    {
        id:"deadpool",
        img:[
            "http://nginx.img2anime.click/static_beahero/slide_character/deadpool_character_1.png",
            "http://nginx.img2anime.click/static_beahero/slide_character/deadpool_character_2.png",
            "http://nginx.img2anime.click/static_beahero/slide_character/deadpool_character_3.png"
        ],
        backGroungImg:"url(http://nginx.img2anime.click/static_beahero/slide_background/deadpool_background.jpg)",
        title: "http://nginx.img2anime.click/static_beahero/slide_logo/deadpool_logo_1.png",
        text:"",
        primaryColor: "#850820",
        isActive: true
    },
    {
        id:"superman",
        img:[
            "http://nginx.img2anime.click/static_beahero/slide_character/superman_character_1.png",
            "http://nginx.img2anime.click/static_beahero/slide_character/superman_character_2.png",
            "http://nginx.img2anime.click/static_beahero/slide_character/superman_character_3.png"
        ],
        backGroungImg:"url(http://nginx.img2anime.click/static_beahero/slide_background/superman_background.jpg)",
        title:"http://nginx.img2anime.click/static_beahero/slide_logo/superman_logo_1.png",
        text:"",
        primaryColor: "#2c4961",
        isActive: true
    },
    {
        id:"wolverine",
        img:[
            "http://nginx.img2anime.click/static_beahero/slide_character/wolverine_character_1.png",
            "http://nginx.img2anime.click/static_beahero/slide_character/wolverine_character_2.png",
            "http://nginx.img2anime.click/static_beahero/slide_character/wolverine_character_3.png"
        ],
        backGroungImg:"url(http://nginx.img2anime.click/static_beahero/slide_background/wolverine_background.jpg)",
        title:"Wolverine",
        text:"",
        primaryColor: "#dea61e",
        isActive: false
    },
    {
        id:"captain",
        img:[
            "http://nginx.img2anime.click/static_beahero/slide_character/captain_character_1.png",
            "http://nginx.img2anime.click/static_beahero/slide_character/captain_character_2.png",
            "http://nginx.img2anime.click/static_beahero/slide_character/captain_character_3.png"
        ],
        backGroungImg:"url(http://nginx.img2anime.click/static_beahero/slide_background/captain_background.jpg)",
        title:"http://nginx.img2anime.click/static_beahero/slide_logo/captain_logo_1.png",
        text:"",
        primaryColor: "#072c3d",
        isActive: true
    },
    {
        id:"iron_man",
        img:[
            ironman,ironman,ironman
        ],
        backGroungImg:`url(${ironman_background})`,
        title:"",
        text:"",
        primaryColor: "#7f1b32",
        isActive: true
    },
    {
        id:"spider_man",
        img:[
            spiderman,spiderman,spiderman
        ],
        backGroungImg:`url(${spiderman_background})`,
        title:"",
        text:"",
        primaryColor: "#7e1c0e",
        isActive: true
    },
    {
        id:"black_panther",
        img:[
            blackpanther,blackpanther,blackpanther
        ],
        backGroungImg:`url(${blackpanther_background})`,
        title:"",
        text:"",
        primaryColor: "#162a34",
        isActive: true
    },
    {
        id:"loki",
        img:[
            loki,loki,loki
        ],
        backGroungImg:`url(${loki_background})`,
        title:"",
        text:"",
        primaryColor: "#264038",
        isActive: true
    },
    {
        id:"thanos",
        img:[
            thanos,thanos,thanos
        ],
        backGroungImg:`url(${thanos_background})`,
        title:"",
        text:"",
        primaryColor: "#895a7c",
        isActive: true
    },
    {
        id:"thor",
        img:[
            thor,thor,thor
        ],
        backGroungImg:`url(${thor_background})`,
        title:"",
        text:"",
        primaryColor: "#0a2a3c",
        isActive: true
    },
    {
        id:"doctor_strange",
        img:[
            drstrange,drstrange,drstrange
        ],
        backGroungImg:`url(${drstrange_background})`,
        title:"",
        text:"",
        primaryColor: "#105077",
        isActive: true
    },
    {
        id:"star_lord",
        img:[
            starlord,starlord,starlord
        ],
        backGroungImg:`url(${starlord_background})`,
        title:"",
        text:"",
        primaryColor: "#3e1618",
        isActive: true
    },
]
//END WEB CONFIG CONST


// SHARED CONFIG
export const IMAGE_TYPE = {
    UPLOAD: "UPLOAD",
    TRANSFORM: "TRANSFORM",
    CLOSE: "CLOSE",
    NAVIGATE: "NAVIGATE"
}
export enum ErrorHttpStatusCode {
    NUMBER_400 = 400,
    NUMBER_401 = 401,
    NUMBER_403 = 403,
    NUMBER_404 = 404,
    NUMBER_422 = 422,
    NUMBER_500 = 500,
  }
export const JOB_PROGRESS_INTERVAL = 6000;
export const FAKE_PROGRESS_INTERVAL = 100;
export const QUEUE_JOB_PROGRESS_INTERVAL = 5000;
export const ONE_SECOND = 1000;
export const TOP_IMG_POSITION = 1050;